import store from '@/store/index';

export default (store.getters.isAdmin
	? [
			// ADMIN PAGES
			{
				title: 'Home',
				route: 'home-admin',
				icon: 'HomeIcon'
			},
			{
				title: 'Users',
				route: 'users',
				icon: 'UsersIcon'
			},
			{
				title: 'Services',
				route: 'services',
				icon: 'LayersIcon'
			},

			{
				title: 'Vouchers',
				route: 'vouchers',
				icon: 'GiftIcon'
			},

			{
				title: 'Notification',
				route: 'notification',
				icon: 'BellIcon'
			},
			{
				header: 'Reports'
			},
			{
				title: 'Orders',
				route: 'all-orders',
				icon: 'ClockIcon'
			},
			{
				title: 'Invoices',
				route: 'invoices',
				icon: 'FileTextIcon'
			}
		]
	: [
			// USER PAGES
			{
				title: 'Home',
				route: 'home',
				icon: 'HomeIcon'
			},
			{
				title: 'Add Funds',
				route: 'deposit',
				icon: 'DollarSignIcon'
			},
			{
				title: 'IMEI Check',
				route: 'imei-check',
				icon: 'SmartphoneIcon'
			},
			{
				title: 'Order History',
				route: 'orders',
				icon: 'ClockIcon'
			},
			{
				title: 'Advanced History',
				route: 'advanced-history',
				icon: 'ListIcon'
			},
			{
				title: 'Invoices',
				route: 'invoices-history',
				icon: 'FileTextIcon'
			},
			{
				title: 'Settings',
				route: 'user-settings',
				icon: 'SettingsIcon'
			},
			{
				title: 'Api Manage',
				route: 'api-manage',
				icon: 'ApiIcon'
			},
			
		]);
